// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';
import { Divider, Paper, Typography } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useEffect, useState } from 'react';
import { GetAllCustomBundlesThunk } from '../../bundle/bundleThunk';
import { useAppDispatch, useAppSelector } from 'Hooks';
import DeleteBundleDialog from './DeleteBundleDialog';
import CustomBundleItem from './CustomBundleItem';

const CustomBundles = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const _id = _ADMIN_ ? useAppSelector((state) => state.admin.orgId) : useAppSelector((state) => state.account.org._id);
  const { customBundles } = useAppSelector((state) => state.bundle);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedBundleId, setSelectedBundleId] = useState('');

  useEffect(() => {
    const fetchCustomBundles = async () => {
      await dispatch(GetAllCustomBundlesThunk(_id)); 
    }

    fetchCustomBundles();
  }, [])

  const handleOpenDeleteDialog = (bundleId:string) =>{
    setSelectedBundleId(bundleId)
    setDeleteDialogOpen(true)
  }

  return (
    <Box sx={styles.contentWrapper}>
      <Box>
        <Typography variant='h3' sx={styles.customBundlesTitle}>Custom Bundles</Typography>
        <Divider sx={styles.divider} />
        <Paper elevation={1} sx={styles.paper}>
          {customBundles.length > 0 ? (
            customBundles.map((bundle, index) => (
              <CustomBundleItem key={index} bundle={bundle} handleOpenDeleteDialog={handleOpenDeleteDialog}/>
            ))
          ) : (
            <>
              <FormatListBulletedIcon />
              <Typography variant='body1' sx={styles.noBundlesText}>
                Currently, you haven&apos;t saved any custom bundles. You can save them during checkout when finalizing your order.
              </Typography>
            </>
          )}
        </Paper>
      </Box>
      <DeleteBundleDialog bundleId={selectedBundleId} setSelectedBundleId={setSelectedBundleId} open={deleteDialogOpen} setOpen={setDeleteDialogOpen}/>
    </Box>
  );
}

export default CustomBundles;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { ListItem, FormControlLabel, Typography, Box, Checkbox, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import stylesDef from './styles';

interface LayerListItemProps {
  item: IDeliverable;
  activeOrder: any;
  ugAlaCarteBoolean: boolean;
  onAddOnsClick: (selectedList: { item: IDeliverable, checked: boolean, typeFactor3D?:boolean }[]) => void;
  isUnavailable: boolean;
}

const LayerListItem = ({ item, activeOrder, ugAlaCarteBoolean, onAddOnsClick, isUnavailable }: LayerListItemProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const { selectedAdditionals, selectedBundle } = useAppSelector((state) => state.bundle);
  const [type, setType] = useState('2D');

  const { amtChild } = useFlags();
  const { type: bundleType } = selectedBundle || {};
  const showCredit = !amtChild && bundleType !== 'utilities';

  useEffect(() => {
    const selectedAddOn = selectedAdditionals.find((i) => i._id === item._id);
    if (selectedAddOn) {
      setType(selectedAddOn.is3D ? '3D' : '2D');
    }
  }, [selectedAdditionals]);

  const handleCheck = (addOnItem:any, checked:boolean) => {
    if (type === '3D') {
      onAddOnsClick([{ item: addOnItem, checked, typeFactor3D: true }]);
    } else {
      onAddOnsClick([{ item: addOnItem, checked, typeFactor3D: false }]);
    }
  };

  const handleSwitchChange = (newType: string) => {
    const checked = selectedAdditionals.findIndex((i) => i._id === item._id) !== -1;

    if (!checked) {
      setType(newType);
    } else if (newType === '3D') {
      onAddOnsClick([{ item, checked: false, typeFactor3D: false }, { item, checked, typeFactor3D: true }]); // remove the 2D cost and add the 3D cost
    } else if (newType === '2D') {
      onAddOnsClick([{ item, checked: false, typeFactor3D: true }, { item, checked, typeFactor3D: false }]); // remove the 3D cost and add the 2D cost
    }
  };

  return (
    <Box sx={isUnavailable && styles.unavailableDeliverables}>
      <ListItem sx={styles.listItem}>
        <FormControlLabel
          label={(
            <Box>
              <Typography variant="body1" display="inline" sx={styles.itemName}>{item.name}</Typography>
            </Box>
          )}
          control={(
            <Checkbox
              sx={styles.checked}
              checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
              onChange={(e) => handleCheck(item, e.target.checked)}
            />
          )}
        />
        {ugAlaCarteBoolean && item.typeFactor.length > 1 ? (
          <>
            <ToggleButtonGroup
              value={type}
              exclusive
              onChange={(_event, value) => handleSwitchChange(value)}
            >
              <ToggleButton
                value="2D"
                sx={type === '2D' ? styles.selected : styles.notSelected}
                disabled={type === '2D'}
              >
                2D
              </ToggleButton>
              <ToggleButton
                value="3D"
                sx={type === '3D' ? styles.selected : styles.notSelected}
                disabled={type === '3D'}
              >
                3D
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography sx={styles.right} component="span">
              {showCredit && `  ${(type === '2D' ? item.typeFactor[0] * activeOrder.acreage : item.typeFactor[1] * activeOrder.acreage).toFixed(2)} Credits`}
            </Typography>
          </>
        ) : (
          <Typography sx={styles.right} component="span">
            {showCredit && `  ${(item.typeFactor[0] * activeOrder.acreage).toFixed(2)} Credits`}
          </Typography>
        )}
      </ListItem>
    </Box>
  );
};

export default LayerListItem;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import deliveryEstimate from 'Utils/deliverables';
import { DeleteCustomBundleAction, GetAllCustomBundlesAction, GetBundlesFailureAction, GetBundlesStartAction, GetBundlesSuccessAction, ResetBundleStateAction, SelectedAdditionalDeliverablesAndLayersAction, SetAdditionalInstructionsAction, SetDeliverablesAction, SetDeliveryDateAction, SetDeliveryDaysAction, SetDensityAction, SetNewCustomBundleAction, SetTopoMajrAction, SetTopoMinrAction } from './bundleActions';

const initialState: IBundleState = {
  list: [],
  availableBundles: [],
  additionalDeliverables: [],
  bundleDeliverables: [],
  selectedBundle: null,
  extraDeliverablesCost: 0,
  totalCost: 0,
  extraLayersCost: 0,
  selectedAdditionals: [],
  topoMajr: null,
  topoMinr: null,
  density: 'normal',
  densityOptions: [],
  deliverySpeed: 'normal',
  deliveryDays: 0,
  deliveryDate: null,
  loading: false,
  additionalInstructions: '',
  newCustomBundle: null,
  customBundles: [],
};

const bundleSlice = createSlice({
  name: 'bundle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetBundlesStartAction, (state) => {
        state.loading = true;
      })
      .addCase(GetBundlesSuccessAction, (state, action) => {
        state.list = action.payload.deliverables.groupings;
        state.availableBundles = action.payload.deliverables.bundles;
        state.densityOptions = action.payload.deliverables.densityOptions;
        state.loading = false;
      })
      .addCase(GetAllCustomBundlesAction, (state, action) => {
        state.customBundles = action.payload;
      })
      .addCase(DeleteCustomBundleAction, (state, action) => {
        const customBundles = state.customBundles.filter((bundle) => bundle._id !== action.payload);
        state.customBundles = customBundles;
      })
      .addCase(GetBundlesFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(ResetBundleStateAction, () => initialState)
      .addCase(SetNewCustomBundleAction, (state, action) => {
        state.newCustomBundle = action.payload;
      })
      .addCase(SetDensityAction, (state, action) => {
        state.density = action.payload;
      })
      .addCase(SetDeliverablesAction, (state, action) => {
        state.additionalDeliverables = action.payload.additionalDeliverables;
        state.bundleDeliverables = action.payload.bundleDeliverables;
        state.selectedBundle = action.payload;
        state.extraLayersCost = 0;
        state.extraDeliverablesCost = 0;
        state.selectedAdditionals = [];
        state.totalCost = action.payload.bundleCost;
        state.density = action.payload.density;
        state.deliverySpeed = action.payload.deliverySpeed;
        state.deliveryDays = action.payload.bundleDeliveryTime;
        state.deliveryDate = deliveryEstimate(action.payload.bundleDeliveryTime);
      })
      .addCase(SelectedAdditionalDeliverablesAndLayersAction, (state, action) => {
        const index = state.selectedAdditionals.findIndex((s) => s._id === action.payload._id);
        if (index >= 0) {
          state.selectedAdditionals.splice(index, 1);
          if (action.payload.type === 'deliverable') {
            state.extraDeliverablesCost = +(state.extraDeliverablesCost - action.payload.cost).toFixed(2);
          } else {
            state.extraLayersCost = +(state.extraLayersCost - action.payload.cost).toFixed(2);
          }
        } else {
          state.selectedAdditionals.push(action.payload);
          if (action.payload.type === 'deliverable') {
            state.extraDeliverablesCost = +(state.extraDeliverablesCost + action.payload.cost).toFixed(2);
          } else {
            state.extraLayersCost = +(state.extraLayersCost + action.payload.cost).toFixed(2);
          }
        }
        state.totalCost = +(state.selectedBundle.bundleCost).toFixed(2);
      })
      .addCase(SetDeliveryDateAction, (state, action) => {
        state.deliveryDate = action.payload;
      })
      .addCase(SetDeliveryDaysAction, (state, action) => {
        state.deliveryDays = action.payload;
      })
      .addCase(SetTopoMajrAction, (state, action) => {
        state.topoMajr = action.payload;
      })
      .addCase(SetTopoMinrAction, (state, action) => {
        state.topoMinr = action.payload;
      })
      .addCase(SetAdditionalInstructionsAction, (state, action) => {
        state.additionalInstructions = action.payload;
      });
  },
});

export const { reducer } = bundleSlice;

export default reducer;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import stylesDef from './styles';

interface BundleButtonsProps {
  bundleStep: number,
  setBundleStep: (bundleStep: number) => void;
  bundleType: string;
}

const BundleButtons = ({ bundleStep, setBundleStep, bundleType }: BundleButtonsProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const handleBack = () => {
    setBundleStep(bundleStep - 1);
  };

  const handleNext = () => {
    setBundleStep(bundleStep + 1);
  };

  return (
    <Box sx={styles.buttonsContainer}>
      {bundleStep !== 0 && bundleType !== 'custom' ? <Button variant="outlined" onClick={handleBack}>Back</Button> : <div />}
      {bundleStep !== 2 && bundleType !== 'custom' ? <Button variant="contained" color="primary" onClick={handleNext}>Next</Button> : <div />}
    </Box>
  );
};

export default BundleButtons;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Box, useTheme } from '@mui/system';
import { Button, Divider, IconButton, List, ListItem, ListItemIcon, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import stylesDef from './styles';

interface CustomBundleItemProps {
  bundle: ICustomBundle;
  handleOpenDeleteDialog: (bundleId: string) => void;
}

const CustomBundleItem = ({ bundle, handleOpenDeleteDialog }: CustomBundleItemProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const [showMore, setShowMore] = useState(false);

  return (
    <Paper elevation={1} sx={styles.paper}>
      <Box sx={styles.customBundleItemContainer}>
        <Box sx={styles.content}>
          <Typography variant='h4' sx={styles.bundleTitle}>{bundle.name}</Typography>
          {
            bundle.createdAt && (
              <Typography variant='body1' sx={styles.bundleDate}>
                Created on: {new Date(bundle.createdAt).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
              </Typography>
            )
          }
        </Box>
        <Box sx={styles.contentLists}>
          {/* layers */}
          <Box sx={styles.contentList}>
            <Typography sx={styles.contentCounter}>{bundle.layers.length}</Typography>
            <Typography sx={styles.contentCounterText}>Layers</Typography>
          </Box>
          {/* deliverables */}
          <Box sx={styles.contentList}>
            <Typography sx={styles.contentCounter}>{bundle.deliverables.length}</Typography>
            <Typography sx={styles.contentCounterText}>Deliverables</Typography>
          </Box>
          <Box sx={styles.buttonGroup}>
            <Button onClick={() => setShowMore(!showMore)}>{showMore ? "HIDE LAYERS" : "SHOW LAYERS"}</Button>
            <IconButton onClick={() => handleOpenDeleteDialog(bundle._id)}>
              <DeleteIcon sx={styles.deleteIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {
        showMore && (
          <>
            <Divider sx={styles.divider} />
            <Box sx={styles.expandedContent}>
              <Box sx={styles.expandedColumn}>
                <Typography variant='h5'>Layers</Typography>
                <List>
                  {
                    bundle.layers.map((layer: ICustomBundleAddon) => (
                      <ListItem sx={styles.bundleListItem} key={layer._id}>
                        <ListItemIcon sx={styles.listItemIcon}>
                          <CheckIcon sx={styles.finishedIcon} />
                        </ListItemIcon>
                        <Typography>{layer.name}</Typography>
                      </ListItem>
                    ))
                  }
                </List>
              </Box>
              <Box sx={styles.expandedColumn}>
                <Typography variant='h5'>Deliverables</Typography>
                <List>
                  {
                    bundle.deliverables.map((deliverable: ICustomBundleAddon) => (
                      <ListItem sx={styles.bundleListItem} key={deliverable._id}>
                        <ListItemIcon sx={styles.listItemIcon}>
                          <CheckIcon sx={styles.finishedIcon} />
                        </ListItemIcon>
                        <Typography>{deliverable.name}</Typography>
                      </ListItem>
                    ))
                  }
                </List>
              </Box>
            </Box>
          </>
        )
      }
    </Paper>
  )
}

export default CustomBundleItem;

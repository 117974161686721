// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-business-days';
import { Moment } from 'moment';
import { Button, Typography, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Form } from 'react-final-form';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { CreateProjectThunk } from 'Features/project/projectThunk';
import { ReloadProjectsThunk, ResetFiltersThunk } from 'Features/projectList/projectListThunk';
import { projectTypes } from 'Utils/constants';
import { TextField, Validators } from '../../forms';
import stylesDef from './styles';

interface CreateProjectFormProps {
  onCreate: (project: IProject) => void;
  onUpload: (project: IProject) => void;
  onCreateEstimate: (project: IProject) => void;
}

const CreateProjectForm = ({ onUpload, onCreate, onCreateEstimate }: CreateProjectFormProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { resources } = useAppSelector((state) => state.auth);
  const org = useAppSelector((state) => state.admin.org);
  const editorFunctions = !_ADMIN_ ? 'editorFunctions' in resources : true;
  const [selectedDate, setSelectedDate] = useState(null);
  const [projectType, setProjectType] = useState('');
  const [error, setError] = useState(false);
  const telecom = _ADMIN_ ? org.telecom : useFlags()?.telecom;

  const handleDateChange = (date: Moment) => {
    setSelectedDate(date);
  };

  const handleProjectTypeChange = (e: any) => {
    setProjectType(e.target.value);
    setError(false);
  };

  const validateDate = () => {
    if (selectedDate) {
      const anticipatedKickoffDate = selectedDate.format('YYYY-MM-DD');
      const today = moment(new Date()).format('YYYY-MM-DD');
      if (moment(today).isAfter(moment(anticipatedKickoffDate))) return 'Invalid date';
      return anticipatedKickoffDate;
    }
    return undefined;
  };

  const onFormSubmit = async (values: any) => {
    if (telecom && !projectType.length) {
      setError(true);
      return;
    }
    const submitType = values?.submitType;
    const anticipatedKickoffDate = validateDate();
    if (anticipatedKickoffDate === 'Invalid date') return;
    delete values.submitType;
    const result = await dispatch(CreateProjectThunk({ ...values, ...(telecom && { type: projectType }), anticipatedKickoffDate }, editorFunctions));
    if (!_ADMIN_) {
      dispatch(ResetFiltersThunk());
      dispatch(ReloadProjectsThunk());
    }

    if (result.success) {
      if (submitType === 'processProject') {
        window?.pendo?.track('Process A Project', { projectId: result.data?._id });
        if (_ADMIN_) onCreate(result.data);
        onUpload(result.data);
      } else {
        window?.pendo?.track('Create Estimate', {
          ...(anticipatedKickoffDate && { kickoff_date: selectedDate?.format('MM-DD-YYYY') }),
          projectId: result.data?._id,
        });
        onCreateEstimate(result.data);
      }
    }
  };

  return (
    <>
      <Box sx={styles.pageTitle}>
        <Typography variant="h1">Start New Project</Typography>
      </Box>
      <Form
        onSubmit={onFormSubmit}
        render={({ form, handleSubmit }) => (
          <form
            style={styles.form}
            onSubmit={handleSubmit}
          >
            <Box sx={styles.fields}>
              <TextField
                name="name"
                label="Project Name"
                variant="outlined"
                placeholder="Enter Project Name"
                validate={Validators.required}
                sx={styles.textFiled}
              />
              {telecom && (
                <FormControl sx={styles.projectType} error={error}>
                  <InputLabel id="project-type-label">Project Type</InputLabel>
                  <Select
                    value={projectType}
                    label="Project Type"
                    labelId="project-type-label"
                    onChange={handleProjectTypeChange}
                    sx={{ ...styles.textFiled, ...styles.capitalize }}
                  >
                    {projectTypes.filter(item => item !== 'custom').map((type) => <MenuItem key={type} value={type} sx={styles.capitalize}>{type}</MenuItem>)}
                  </Select>
                  {error && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            </Box>
            <TextField
              name="description"
              label="Description"
              placeholder="Description"
              variant="outlined"
              sx={styles.textFiled}
            />
            <Box sx={styles.fields}>
              <TextField
                name="referenceId"
                label="Reference ID"
                placeholder="Reference ID"
                variant="outlined"
                sx={styles.textFiled}
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  allowKeyboardControl={false}
                  variant="inline"
                  format="MM/DD/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  sx={styles.datePicker}
                  label="Anticipated Kickoff"
                  placeholder="Anticipated Kickoff"
                  disablePast
                  disableToolbar
                  minDateMessage="Date should not be past date"
                />
              </LocalizationProvider>
            </Box>
            <Box sx={styles.buttonsWrapper}>
              <Button
                variant="outlined"
                sx={styles.uploadFormSubmit}
                type="submit"
                onClick={() => { form.change('submitType', 'processProject'); }}
              >
                PROCESS A PROJECT
              </Button>
              {_ADMIN_ ? '' : (
                <Button
                  variant="outlined"
                  sx={styles.uploadFormSubmit}
                  type="submit"
                  onClick={() => { form.change('submitType', 'createEstimate'); }}
                >
                  CREATE ESTIMATE
                </Button>
              )}
            </Box>
          </form>
        )}
      />
    </>
  );
};

CreateProjectForm.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onCreateEstimate: PropTypes.func.isRequired,
};

export default CreateProjectForm;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, IconButton, Box, Fade, CircularProgress } from "@mui/material"
import { useTheme } from "@mui/system";
import stylesDef from "./styles";
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from "Hooks";
import { DeleteCustomBundleThunk } from "Features/bundle/bundleThunk";
import { useState } from "react";

interface DeleteBundleDialogProps {
  open: boolean;
  bundleId?: string;
  setOpen: (value: boolean) => void;
  setSelectedBundleId?: (value: string) => void;
}

const DeleteBundleDialog = ({ bundleId, open, setOpen, setSelectedBundleId }: DeleteBundleDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
    setSelectedBundleId('');
  }

  const handleDelete = async () => {
    setIsLoading(true);
    await dispatch(DeleteCustomBundleThunk(bundleId));
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={styles.dialogContainer}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={styles.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.dialogTitle}>
          <ErrorIcon sx={styles.errorIcon}/>
          <Typography sx={styles.dialogTitleText}>Are you sure?</Typography> 
        </Box>
        <Typography>By deleting this bundle, you won&apos;t be able to use it on future orders. Are you sure you want to delete it?</Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={styles.cancelButton} onClick={handleClose}>Cancel</Button>
        <Button sx={styles.deleteButton} onClick={handleDelete} disabled={isLoading}>
          <Fade in={isLoading}>
            <CircularProgress size={24} sx={styles.buttonProgress}/>
          </Fade>
          <Fade in={!isLoading}>
            <span>Delete</span>
          </Fade>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteBundleDialog;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Paper, Button, CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PDFDownloadLink } from '@react-pdf/renderer';
import downloadIcon from 'Assets/download-dark-icon.png';
import { formatValue, deliveryBusinessDays } from 'Utils/deliverables';
import { useAppDispatch, useAppSelector } from 'Hooks';
import OrderDialog from 'Features/order';
import { RootState } from 'Store';
import { selectAvailableTeams } from 'Features/account/accountSelectors';
import { GetOrdersThunk } from 'Features/order/orderThunk';
import CustomOrderDialog from 'Features/order/CustomOrderDialog';
import PDF from 'Features/order/PDF';
import ProcessedAcreage from 'Components/ProcessedAcreage';
import Sidebar from 'Components/Sidebar';
import UploadFilesDialog from 'Components/UploadFilesDialog';
import { getOrders } from 'Features/order/orderSelectors';
import { HideUploadSingleFileDialogAction } from 'Features/fileUpload/fileUploadActions';
import { getRasterRequestStatus, selectCRSInfo } from '../projectSelectors';
import Autosuggest from './Autosuggest';
import KmlPanel from './KmlPanel';
import DataSetsPanel from './DataSetsPanel';
import SelectionInfo from './SelectionInfo';
import stylesDef from './styles';

const ProjectSidebar = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const { project, epsgCode } = useAppSelector((state) => state.project);
  const { list: templateList } = useAppSelector((state) => state.cadTemplate);
  const automatePlanType = useAppSelector((state) => state.subscription.automateSubscription?.priceName);
  const { screenshot } = useAppSelector((state) => state.map.common);
  const order = useAppSelector((state: RootState) => getOrders(state).activeOrder);
  const rasterRequest = useSelector((state: RootState) => getRasterRequestStatus(state));
  const { availableTeams } = useSelector((state: RootState) => selectAvailableTeams(state));
  const { geogCS } = useSelector((state: RootState) => selectCRSInfo(state));

  const { name: projectName, imageryRequest, _id: projectId } = project || {};
  const { bundleName, bundleAcreAdjustmentFactor, topoIntervalMajor, topoIntervalMinor, addOns, density, deliverySpeed, deliveryDays, deliverables, imageryCreditCost, imageryDollarCost, creditAcreage, costToCustomer, adjustedOrderAcres, paidForThisProject, rasterizationCreditCost, rasterizationDollarCost, salesTaxAmount } = order || {};
  const noCostString = '0 credits ($0.00)';
  const imageryRequested = (order?.isEstimate && !!(project?.imageryRequest?.requested)) || !!(project?.imageryRequest?.uploaded) || !!(project?.imageryRequest?.notificationSent);

  const [orderFormVisible, setOrderFormVisible] = useState(false);
  const [customOrderFormVisible, setCustomOrderFormVisible] = useState(false);
  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);
  const [deliverableAddOnString, setDeliverableAddOnString] = useState(noCostString);
  const [totalAddOnString, setTotalAddOnString] = useState(noCostString);
  const [layerAddOnString, setLayerAddOnString] = useState(noCostString);
  const [totalAddOnDollars, setTotalAddOnDollars] = useState(0);
  const needUploadFiles = (!order?.boundaryFile);
  const { customCheckoutOptionalRasterization, amtChild } = useFlags();

  useEffect(() => {
    if ((imageryRequest || !!rasterizationCreditCost) && order.isEstimate) {
      const loadData = async () => {
        await dispatch(GetOrdersThunk(projectId));
      };

      loadData();

      const addOnDeliverables = addOns.filter((del) => del.type === 'deliverable');
      const totalAddOnDeliverbalesCredits = addOnDeliverables.reduce((acc, obj) => acc + obj.acres, 0);
      const totalAddOnDeliverbalesDollars = addOnDeliverables.reduce((acc, obj) => acc + obj.cost, 0);
      setDeliverableAddOnString(`${totalAddOnDeliverbalesCredits} credits ($${formatValue(totalAddOnDeliverbalesDollars)})`);

      const addOnLayers = addOns.filter((del) => del.type === 'layer');
      const totalAddOnLayersCredits = addOnLayers.reduce((acc, obj) => acc + obj.acres, 0);
      const totalAddOnLayersDollars = addOnLayers.reduce((acc, obj) => acc + obj.cost, 0);
      setLayerAddOnString(`${totalAddOnLayersCredits} credits ($${formatValue(totalAddOnLayersDollars)})`);

      const totalAddOnCredits = 
            (totalAddOnDeliverbalesCredits ?? 0) + 
            (totalAddOnLayersCredits ?? 0) + 
            (imageryCreditCost ?? 0) + 
            (rasterizationCreditCost ?? 0);
      const addOnDollars = 
            (totalAddOnDeliverbalesDollars ?? 0) + 
            (totalAddOnLayersDollars ?? 0) + 
            (imageryDollarCost ?? 0) + 
            (rasterizationDollarCost ?? 0);
      setTotalAddOnDollars(addOnDollars);
      setTotalAddOnString(`${totalAddOnCredits} credits ($${formatValue(addOnDollars)})`);
    }
  }, [order?.isEstimate, order?.costToCustomer]);

  const onButtonClick = () => {
    if (customCheckoutOptionalRasterization && customCheckoutOptionalRasterization.raster !== undefined) {
      setCustomOrderFormVisible(true);
    } else {
      setOrderFormVisible(true);
    }
  };

  const currentTeam = availableTeams?.find((t) => t._id === order.team);
  const pdfData = useMemo(() => ({
    type: 'Estimate',
    submitter: order?.ownerEmail || 'N/A',
    automatePlanType,
    screenshot,
    salesTaxAmount,
    businessDays: deliveryDays ? deliveryBusinessDays(deliveryDays) : 'N/A',
    projectName,
    referenceId: order?.referenceId,
    team: currentTeam?.name,
    acreage: order?.acreage,
    pricePerFoot: order?.pricePerFoot,
    middlePoint: `${order?.middlePoint?.place}, ${order?.middlePoint?.region} ${order?.middlePoint?.postcode}`,
    coordinates: `${order?.middlePoint?.longitude.toFixed(5)}, ${order?.middlePoint?.latitude.toFixed(5)}`,
    cadTemplate: templateList.find((temp) => temp._id === order?.cadTemplate)?.name,
    crs: epsgCode && `${geogCS} - EPSG: ${epsgCode}`,
    totalAdditionalCharged: paidForThisProject?.toFixed(2),
    topoMajr: topoIntervalMajor,
    topoMinr: topoIntervalMinor,
    selectedBundle: { name: bundleName, acreAdjustmentFactor: bundleAcreAdjustmentFactor, density, deliverySpeed },
    bundleDeliverables: deliverables,
    selectedAdditionals: addOns,
    totalCostForProject: costToCustomer?.toFixed(2),
    chargedCredits: adjustedOrderAcres,
    chargedCreditsInDollars: (costToCustomer - totalAddOnDollars)?.toFixed(2),
    totalCreditsCharged: creditAcreage,
    totalCreditsChargedInDollars: costToCustomer?.toFixed(2),
    addOnsCreditsString: totalAddOnString,
    deliverablesCreditsString: deliverableAddOnString,
    layersCreditsString: layerAddOnString,
    anticipatedKickoffDate: order?.anticipatedKickoffDate,
    imageryProvider: imageryRequest?.provider,
    imageryCreditCost,
    imageryDollarCost,
    rasterizationCreditCost,
    rasterizationDollarCost,
    additionalInstructions: order?.additionalInstructions,
    amtChild,
  }), [order, totalAddOnString, layerAddOnString, screenshot]);

  return (
    <Sidebar onClick={() => dispatch(HideUploadSingleFileDialogAction())}>
      <Box sx={styles.sidebarContent}>
        <Autosuggest />
        <Divider />
        <KmlPanel />
        <Divider />
        <DataSetsPanel />
        <Divider />
        <SelectionInfo />
        <Box sx={styles.acreageWrapper}>
          <ProcessedAcreage />
        </Box>
      </Box>
      {order?.isEstimate && order?.bundleId && (imageryRequested || rasterRequest.requested) && screenshot ? (
        <Box sx={styles.downloadContainer}>
          <PDFDownloadLink document={<PDF data={pdfData} />} fileName={`${projectName}_estimate.pdf`} style={styles.pdfButton}>
            {({ blob, url, loading }) =>
              (loading ? <CircularProgress size={24} /> : (
                <Box
                  sx={styles.orderButton}
                >
                  <img style={styles.downloadImage} src={downloadIcon} alt="download" />
                  DOWNLOAD ESTIMATE
                </Box>
              ))}
          </PDFDownloadLink>
        </Box>
      )
        : (
          <Paper sx={styles.orderButtonWrapper} elevation={2}>
            <span>
              <Button
                variant="outlined"
                fullWidth
                sx={styles.orderButton}
                onClick={onButtonClick}
                disabled={needUploadFiles || order.acreage <= 0 || imageryRequested || rasterRequest.rejected}
              >
                Go to Checkout
              </Button>
            </span>
          </Paper>
        )}
      <OrderDialog orderFormVisible={orderFormVisible} setOrderFormVisible={setOrderFormVisible} />
      <CustomOrderDialog orderFormVisible={customOrderFormVisible} setOrderFormVisible={setCustomOrderFormVisible} />
      <UploadFilesDialog showDialog={uploadFilesFormVisible} setShowDialog={setUploadFilesFormVisible} />
    </Sidebar>
  );
};

export default ProjectSidebar;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import moment from 'moment';
import { API_URL } from 'Config';
import { getJson, postJson, patchJson, getFileBlob, deleteRequest } from 'Utils/http';
import history from 'Utils/history';
import { GetOrgSubscriptionThunk } from 'Features/account/subscriptionThunk';
import type { RootState } from 'Store';
import { SetNewCustomBundleAction } from 'Features/bundle/bundleActions';
import { getProcessingOrders, getOrders } from './orderSelectors';
import {
  SetOrderStatusAction,
  GetOrdersStartAction,
  GetOrdersSuccessAction,
  GetOrdersFailureAction,
  CreateOrderStartAction,
  CreateOrderSuccessAction,
  CreateOrderFailureAction,
  SetFileVersionAction,
  EditOrderSuccessAction,
  UpdateOrderAction,
  SetOrderLoadingAction,
  SetPlaceOrderSuccessAction,
  UpdateOrderStatusStartAction,
  UpdateOrderStatusSuccessAction,
  UpdateOrderStatusFailureAction,
  UpdateOrderCadFilesAction,
  SetOutputLasAction,
  UpdateOrderGeoDbFilesAction,
  UpdateOrderSurfaceFilesAction,
  SaveFileDownloadStatusAction,
  SaveGeoJsonDownloadStatusAction,
  DownloadFileStartAction,
  DownloadFileSuccessAction,
  DeleteOutputLasFileStartAction,
  DeleteOutputLasFileSuccessAction,
  DeleteOutputLasFileFailureAction,
  DeleteSurfaceFileStartAction,
  DeleteSurfaceFileSuccessAction,
  DeleteSurfaceFileFailureAction,
  DeleteDeliverableFileStartAction,
  DeleteDeliverableFileSuccessAction,
  DeleteDeliverableFileFailureAction,
  DeleteGeoDbFileStartAction,
  DeleteGeoDbFileSuccessAction,
  DeleteGeoDbFileFailureAction,
  DeleteDxfFileStartAction,
  DeleteDxfFileSuccessAction,
  DeleteDxfFileFailureAction,
  CreditAdjustmentStartAction,
  CreditAdjustmentSuccessAction,
  CreditAdjustmentFailureAction,
  SetShowAdjustmentMessageAction,
  UpdateOrderDeliverableFilesAction,
  UpdateOrderLasFilesAction,
  SetOrderIdAction,
  SetTaxDetailsAction,
} from './orderActions';

export const GetOrderStatusThunk = () =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const processingOrders = getProcessingOrders(getState());
    if (!processingOrders.length) return { success: true };

    const { token } = getState().auth;
    const url = `${API_URL}/orders`;

    const result = await getJson<IOrder[]>(url, token);

    if (!result.success) {
      return result;
    }

    const orderStatusResult: {
      [key: string]: number,
    } = {};

    const orderEstimateResult: string[] = [];

    result.data.forEach((order) => {
      if (order.isEstimate) orderEstimateResult.push(order.ownerProject);
      if ([1, 2, 3, 4, 5, 6, 7].includes(order.orderStatus)) orderStatusResult[order.ownerProject] = order.orderStatus;
    });

    dispatch(SetOrderStatusAction({ orderStatus: orderStatusResult, orderEstimate: orderEstimateResult }));
    return result;
  };

export const GetOrdersThunk = (projectId?: string, orgId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orgUrl = _ADMIN_ ? `${API_URL}/admins/getOrgOrders/${orgId}` : `${API_URL}/orders`;
    const url = projectId ? `${API_URL}/orders/${projectId}` : orgUrl;
    const { token } = getState().auth;
    dispatch(GetOrdersStartAction());

    const result = await getJson<IOrder[]>(url, token);
    if (result.success) {
      dispatch(GetOrdersSuccessAction(result.data));
      if (!_ADMIN_) GetOrderStatusThunk()(dispatch, getState);
    } else {
      dispatch(GetOrdersFailureAction(result.message));
    }
    return result;
  };

export const SetOutputLas = (outputLas: boolean) => (dispatch: Dispatch) => dispatch(SetOutputLasAction(outputLas));

export const CreateOrderThunk = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/orders`;
    const { user, token } = getState().auth;
    const { orgId } = getState().admin;

    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    const data = isAdmin ?
      { ownerProject: projectId, orgId } :
      { ownerProject: projectId };

    dispatch(CreateOrderStartAction());

    const result = await postJson<IOrder>(url, data, token);
    if (result.success) {
      dispatch(CreateOrderSuccessAction(result.data));
    } else {
      dispatch(CreateOrderFailureAction());
    }

    return result;
  };

export const EditOrderThunk = (orderData: any) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { orderId, bundleName, density, deliverySpeed, referenceId, team, anticipatedKickoffDate, projectType, addOns, deliveryDate } = orderData;
    const url = `${API_URL}/orders/${orderId}`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;
    const data = {
      orgId,
      ...(bundleName && { bundleName }),
      ...(density && { density }),
      ...(deliverySpeed && { deliverySpeed }),
      ...(referenceId && { referenceId }),
      ...(team && { team }),
      ...(anticipatedKickoffDate && { anticipatedKickoffDate }),
      ...(projectType && { projectType }),
      ...(addOns && { addOns }),
      ...(deliveryDate && { deliveryDate }),
    };

    const result = await patchJson<{ order: IOrder }>(url, data, token);
    if (result.success) {
      dispatch(EditOrderSuccessAction(result.data.order));
    }

    return result;
  };

export const SetOrderIdThunk = (orderId: string) => (dispatch: Dispatch) => dispatch(SetOrderIdAction(orderId));

export const ClearFileVersions = () => (dispatch: Dispatch) => dispatch(SetFileVersionAction({}));

export const SetFileVersionThunk = (orderId: string, siteId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const orders = getOrders(getState());
  const processedOrders = orders.projectOrders.filter((o) => o.cadFiles && o.cadFiles.length > 0);
  let fileVersions = getState().order.fileVersions || {};
  if (!Object.isExtensible(fileVersions)) {
    fileVersions = { ...fileVersions };
  }

  // TODO: Fixes dxf loading issue for now, look into this later - Vinutna - 5/11/2023
  if (fileVersions === {}) {
    processedOrders.forEach((order) => {
      fileVersions[order._id] = '';
    });
  } else {
    fileVersions[orderId] = siteId;
  }
  dispatch(SetFileVersionAction(fileVersions));
};

export const UpdateOrderGeoDbFiles = (geoDbFile: IGeoDbFile) => (dispatch: Dispatch) => dispatch(UpdateOrderGeoDbFilesAction(geoDbFile));

export const UpdateOrderSurfaceFiles = (surfaceFile: ISurfaceFile) => (dispatch: Dispatch) => dispatch(UpdateOrderSurfaceFilesAction(surfaceFile));

export const GeneratePostgisExportFileThunk = (siteId: string, format: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/generatePostgisExportFile/${siteId}`;
    const result = await postJson<IGeoDbFile>(url, { format }, token);
    if (!result.success) {
      return {
        error: true,
      };
    }
    dispatch(UpdateOrderGeoDbFilesAction(result.data));
    return {};
  };

export const GenerateCadTemplateJsonFileThunk = (tempalateId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/generateCadTemplateJsonFile/${tempalateId}`;
    const result = await getJson<{preSignedUrlJson: string, preSignedUrlCadTemplate: string}>(url, token);
    if (!result.success) {
      return {
        error: true,
      };
    }
    setTimeout(() => {
      window.open(result.data.preSignedUrlJson, '_blank');
      window.open(result.data.preSignedUrlCadTemplate, '_self');
    }, 100);
    return result;
  };

// Clear Downloaded file status
export const ClearDownloadFilesThunk = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const oldDownloadStatus = getState().order.dxfFileStatus;
    const arr = [...oldDownloadStatus];
    const index = oldDownloadStatus.findIndex((obj) => obj.siteId === siteId);
    arr.splice(index, 1);

    const oldDownloadGeoJsonStatus = getState().order.geoJsonFileStatus;
    const arrGeoJson = [...oldDownloadGeoJsonStatus];
    const geoJsonIndex = oldDownloadGeoJsonStatus.findIndex((obj) => obj.siteId === siteId);
    arrGeoJson.splice(geoJsonIndex, 1);

    dispatch(SaveFileDownloadStatusAction({ downloadStatus: arr, fileReady: true }));
    dispatch(SaveGeoJsonDownloadStatusAction({ downloadGeoJsonStatus: arrGeoJson, fileReady: true }));
  };

// Set status of LAS file with a given orderId
export const PrepareLAS = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const oldDownloadStatus = getState().order.dxfFileStatus;
    const index = oldDownloadStatus.findIndex((obj) => obj.siteId === siteId);
    const fileReadyStatus = { ...oldDownloadStatus[index].fileReadyStatus, lasFile: 1 };
    const downloadStatus = oldDownloadStatus.map((status, i) => (i === index ? { ...status, fileReadyStatus } : status));
    dispatch(SaveFileDownloadStatusAction({ downloadStatus, fileReady: true }));
  };

// Function to call the DownloadDxf API that triggers a Lambda which further fetches data from the PostGIS DB to create a
// DXF file. If the DownloadDxf API returns a 200, then this function would call the checkDxfStatus API that polls every
// 5 seconds to check if a DXF file has been generated. If the API receives a file URL in the response, the file is
// automatically downloaded. This API will keep getting called even if the user navigates away after clicking on download
// DXF in the UI.
// This type of polling is particularly useful when it comes to generating DXFs for large files since the process of
// file generation could take several minutes.
export const DownloadPostgisDxfThunk = (orderId: string, siteId: string, includeEmptyLayers: boolean, fileDownloadCheckboxState?: { dxfFile: boolean,
  lasFile: boolean,
  surfaceFile: boolean,
  geoDbFile: boolean,
  geoJson: boolean }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    let orgId: string;
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else if ('opsTrainer' in getState().auth.resources) {
      const project = getState().project?.list?.find((p) => p._id === projectId);
      orgId = project?.ownerOrganization;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    let layers: string[] = [];

    // when download dxf from admin portal, always download all layers
    if (!_ADMIN_ || fileDownloadCheckboxState) {
      const { vector_layers } = (getState().map.common.vectorTileJson[orderId] && getState().map.common.vectorTileJson[orderId][siteId]) || {};

      const allLayersVisible =
        vector_layers && vector_layers.every((l) => l.visible);
      const someLayersSelected =
        vector_layers && vector_layers.some((l) => l.visible);

      // set layers array depending on whether all, some or none of the layers are selected
      if (allLayersVisible) {
        layers = [];
      } else if (someLayersSelected) {
        layers = vector_layers.filter((l) => l.visible).map((l) => l.id);
      } else {
        // this is the case when no layers are selected in the sidebar for a site. In this case no API should be called
        return;
      }
    }

    const timeRequested = new Date();
    const url = `${API_URL}/tiles/downloadDxf`;
    const data = {
      orgId,
      projectId,
      orderId,
      cadFileId: siteId,
      layers,
      includeEmptyLayers,
    };
    // 1 prepare download -> clicked -> change the status  in redux orderid:true, polling
    // 2 preparing download -> polling -> success ->change state in redux orderid:finished
    // 3 finished downloading -> onclick => { }
    const result = await postJson<{}>(url, data, token);
    // eslint-disable-next-line no-nested-ternary
    const filesRequested = !_ADMIN_ && (fileDownloadCheckboxState.dxfFile && fileDownloadCheckboxState.geoJson ? 'multiple' : fileDownloadCheckboxState.dxfFile ? 'dxf' : fileDownloadCheckboxState.geoJson ? 'geojson' : null);
    if (result.success) {
      // poll the checkDxfStatus API to check File availability
      const testingVar = setInterval(async () => {
        const url2 = `${API_URL}/tiles/checkDxfStatus`;
        let fileAvailable = false;
        const data2 = {
          orgId,
          projectId,
          orderId,
          filesRequested,
          timeRequested,
          cadFileId: siteId,
        };
        const result2 = await postJson<{ fileURL: any }>(url2, data2, token);
        fileAvailable = result2.success;

        if (fileAvailable) {
          const downloadStatus = getState().order.dxfFileStatus;
          const downloadGeoJsonStatus = getState().order.geoJsonFileStatus;
          const newDownloadStatus: IDxfFileState[] = [...downloadStatus];
          const newDownloadGeoJsonStatus: IGeoJsonFileState[] = [...downloadGeoJsonStatus];

          // find obj from downloadstatus array which has this siteid
          if (_ADMIN_ || filesRequested === 'dxf') {
            const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);

            const updatedStatus = {
              ...downloadStatus[index],
              fileReadyStatus: {
                ...downloadStatus[index].fileReadyStatus,
                dxfFile: 1,
              },
              url: result2.data.fileURL[0].url,
              size: result2.data.fileURL[0].size,
            };

            newDownloadStatus[index] = updatedStatus;
          }
          if (filesRequested === 'geojson') {
            const index = downloadGeoJsonStatus.findIndex((obj) => obj.siteId === siteId);
            const updatedStatus = {
              ...downloadGeoJsonStatus[index],
              fileReadyStatus: {
                ...downloadGeoJsonStatus[index].fileReadyStatus,
                geoJson: 1,
              },
              url: result2.data.fileURL[0].url,
            };
            newDownloadGeoJsonStatus[index] = updatedStatus;
          }
          if (filesRequested === 'multiple') {
            const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);
            const updatedStatus = {
              ...downloadStatus[index],
              fileReadyStatus: {
                ...downloadStatus[index].fileReadyStatus,
                dxfFile: 1,
              },
              url: result2.data.fileURL.find((x: any) => x.fileType === 'dxf').url,
            };

            newDownloadStatus[index] = updatedStatus;

            const geojsonIndex = downloadGeoJsonStatus.findIndex((obj) => obj.siteId === siteId);

            const updatedGeoJsonStatus = {
              ...downloadGeoJsonStatus[geojsonIndex],
              fileReadyStatus: {
                ...downloadGeoJsonStatus[geojsonIndex].fileReadyStatus,
                geoJson: 1,
              },
              url: result2.data.fileURL.find((x: any) => x.fileType === 'geojson').url,
            };

            newDownloadGeoJsonStatus[geojsonIndex] = updatedGeoJsonStatus;
          }
          dispatch(SaveFileDownloadStatusAction({ downloadStatus: newDownloadStatus, fileReady: true }));
          dispatch(SaveGeoJsonDownloadStatusAction({ downloadGeoJsonStatus: newDownloadGeoJsonStatus, fileReady: true }));
          clearInterval(testingVar);
        }
      }, 5000);
    }
  };

export const DownloadGeoDbFileThunk = (fileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = user.ownerOrganization;
    }

    const url = `${API_URL}/downloadGeoDbFile/${orgId}/${projectId}/${orderId}/${fileId}`;
    const result = await getJson<string>(url, token);
    if (result.success) {
      setTimeout(() => {
        window.open(result.data, '_self');
      }, 100);
      return result;
    }
    return {};
  };

// Call API to Download LAS file
export const DownloadLASThunk = (orderId: string, lasFile: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    const url = `${API_URL}/downloadLAS/${orgId}/${projectId}/${orderId}/${lasFile}`;
    const result = await getJson<string>(url, token);

    if (result.success) {
      setTimeout(() => {
        window.open(result.data, '_self');
      }, 1000);
    }

    return result;
  };

// Download LAS(airworks admin only)
export const AdminDownloadLASThunk = (filename: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    const { orgId } = getState().admin;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));

    if (!isAdmin) {
      history.push('/projects');
    }
    const url = `${API_URL}/adminDownloadLAS/${orgId}/${projectId}/${filename}`;
    const result = await getJson<string>(url, token);
    if (result.success) {
      window.open(result.data, '_self');
    }
  };

// Set download status of geojson file
export const SetGeojsonFilesState = (siteId: string, fileDownloadCheckboxState?: IFileStatus) => async (dispatch: Dispatch) => {
  const { geoJson } = !_ADMIN_ && fileDownloadCheckboxState;
  const downloadGeoJsonStatus: object = {
    siteId,
    fileReadyStatus: {},
  };
  if (geoJson || !_ADMIN_) {
    downloadGeoJsonStatus.fileReadyStatus.geoJson = 0;
    dispatch(SaveGeoJsonDownloadStatusAction({ downloadGeoJsonStatus, fileReady: false }));
  }
};

export const SetPlaceOrderSuccess = (data: boolean) => (dispatch: Dispatch) => dispatch(SetPlaceOrderSuccessAction(data));

export const DownloadFileThunk = (type: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orders = getOrders(getState());
    const { activeOrder } = orders;

    const url = `${API_URL}/orders/${activeOrder._id}/${type}`;
    const { token } = getState().auth;

    dispatch(DownloadFileStartAction());

    const result = await getJson(url, token);
    dispatch(DownloadFileSuccessAction());

    return result;
  };

// Set download status of files that have been requested by the user
export const SetDownloadFilesState = (siteId: string, fileDownloadCheckboxState?: IFileStatus) => async (dispatch: Dispatch) => {
  const { dxfFile, lasFile, surfaceFile, geoDbFile } = !_ADMIN_ && fileDownloadCheckboxState;
  const downloadStatus: any = {
    siteId,
    fileReadyStatus: {},
  };
  if (dxfFile || _ADMIN_) {
    downloadStatus.fileReadyStatus.dxfFile = 0;
  }
  if (lasFile && !_ADMIN_) {
    downloadStatus.fileReadyStatus.lasFile = 0;
  }
  if (surfaceFile && !_ADMIN_) {
    downloadStatus.fileReadyStatus.surfaceFile = 0;
  }
  if (geoDbFile && !_ADMIN_) {
    downloadStatus.fileReadyStatus.geoDbFile = 0;
  }
  dispatch(SaveFileDownloadStatusAction({ downloadStatus, fileReady: false }));
};

export const DeleteOutputLasFileThunk = (lasFileId: string, orderId: string, projectId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const { orgId } = getState().admin;
    const url = `${API_URL}/projects/deleteLAS`;

    dispatch(DeleteOutputLasFileStartAction());
    const data = {
      orgId,
      projectId,
      lasFileId,
    };
    const result = await postJson(url, data, token);
    if (result.success) {
      dispatch(DeleteOutputLasFileSuccessAction({ lasFileId, orderId }));
    } else {
      dispatch(DeleteOutputLasFileFailureAction());
    }
  };

export const PrepareSurfaceFile = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const downloadStatus = getState().order.dxfFileStatus;
    const newDownloadStatus: IDxfFileState[] = [...downloadStatus];
    const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);

    const updatedStatus = {
      ...downloadStatus[index],
      fileReadyStatus: {
        ...downloadStatus[index].fileReadyStatus,
        surfaceFile: 1,
      },
    };

    newDownloadStatus[index] = updatedStatus;
    dispatch(SaveFileDownloadStatusAction({ downloadStatus: newDownloadStatus, fileReady: true }));
  };

export const DownloadSurfaceFileThunk = (fileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = user.ownerOrganization;
    }

    const url = `${API_URL}/downloadSurfaceFile/${orgId}/${projectId}/${orderId}/${fileId}`;
    const result = await getJson<string>(url, token);
    if (result.success) {
      setTimeout(() => {
        window.open(result.data, '_self');
      }, 100);
      return result;
    }
    return {};
  };

export const PrepareDeliverableFile = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const downloadStatus = getState().order.dxfFileStatus;
    const newDownloadStatus: IDxfFileState[] = [...downloadStatus];
    const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);

    const updatedStatus = {
      ...downloadStatus[index],
      fileReadyStatus: {
        ...downloadStatus[index].fileReadyStatus,
        deliverableFile: 1,
      },
    };

    newDownloadStatus[index] = updatedStatus;
    dispatch(SaveFileDownloadStatusAction({ downloadStatus: newDownloadStatus, fileReady: true }));
  };

export const DownloadDeliverableFileThunk = (fileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = user.ownerOrganization;
    }

    const url = `${API_URL}/downloadDeliverableFile/${orgId}/${projectId}/${orderId}/${fileId}`;
    const result = await getJson<string>(url, token);
    if (result.success) {
      setTimeout(() => {
        window.open(result.data, '_self');
      }, 100);
      return result;
    }
    return {};
  };

export const PrepareGeoDbFile = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const downloadStatus = getState().order.dxfFileStatus;
    const newDownloadStatus: IDxfFileState[] = [...downloadStatus];
    const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);

    const updatedStatus = {
      ...downloadStatus[index],
      fileReadyStatus: {
        ...downloadStatus[index].fileReadyStatus,
        geoDbFile: 1,
      },
    };

    newDownloadStatus[index] = updatedStatus;
    dispatch(SaveFileDownloadStatusAction({ downloadStatus: newDownloadStatus, fileReady: true }));
  };

export const DownloadGDBWarningFileThunk = (fileId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { user, token } = getState().auth;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (!isAdmin) {
      return {};
    }
    const url = `${API_URL}/downloadGeoDbWarningFile/${fileId}`;
    const result = await getJson<{preSignedUrl: string, fileName: string}>(url, token);
    if (result.success) {
      const { preSignedUrl, fileName } = result.data;
      const blobResult = await getFileBlob(preSignedUrl);
      if (blobResult.success) {
        const fileUrl = URL.createObjectURL(blobResult.data);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();
      }
    }
    return {};
  };

export const DeleteSurfaceFileThunk = (surfaceFileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/deleteSurfaceFile/${surfaceFileId}`;

    dispatch(DeleteSurfaceFileStartAction());

    const result = await deleteRequest(url, token);
    if (result.success) {
      dispatch(DeleteSurfaceFileSuccessAction({ surfaceFileId, orderId }));
    } else {
      dispatch(DeleteSurfaceFileFailureAction());
    }
  };

export const DeleteDeliverableFileThunk = (deliverableFileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/deleteDeliverableFile/${deliverableFileId}`;

    dispatch(DeleteDeliverableFileStartAction());

    const result = await deleteRequest(url, token);
    if (result.success) {
      dispatch(DeleteDeliverableFileSuccessAction({ deliverableFileId, orderId }));
    } else {
      dispatch(DeleteDeliverableFileFailureAction());
    }
  };

export const DeleteGeoDbFileThunk = (geoDbFileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/deleteGeoDbFile/${geoDbFileId}`;

    dispatch(DeleteGeoDbFileStartAction());

    const result = await deleteRequest(url, token);
    if (result.success) {
      dispatch(DeleteGeoDbFileSuccessAction({ geoDbFileId, orderId }));
    } else {
      dispatch(DeleteGeoDbFileFailureAction());
    }
  };

export const DeleteDxfFileThunk = (dxfFileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/deleteDxfFile/${dxfFileId}`;

    dispatch(DeleteDxfFileStartAction());

    const result = await deleteRequest(url, token);
    if (result.success) {
      dispatch(DeleteDxfFileSuccessAction({ dxfFileId, orderId }));
    } else {
      dispatch(DeleteDxfFileFailureAction());
    }
  };

export const AdjustOrderCreditsThunk = (orderId: string, orgId: string, projectId: string, adjustment: number, reason: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/creditadjustment`;

    const { token } = getState().auth;
    const { org } = getState().admin;
    const data = { orderId, orgId, projectId, adjustment, reason };

    dispatch(CreditAdjustmentStartAction());

    const result = await postJson<IOrder>(url, data, token);

    if (result.success) {
      const { order, creditAdjustments, totalAdjustment } = result.data.updatedOrder;

      order.totalAdjustment = totalAdjustment;
      order.creditAdjustments = creditAdjustments;
      dispatch(CreditAdjustmentSuccessAction(order));
    } else {
      const errordata = result.message;
      if (adjustment > org.availableCreditAcreage && errordata?.includes('availableCreditAcreage')) {
        dispatch(SetShowAdjustmentMessageAction(true));
      }
      dispatch(CreditAdjustmentFailureAction(errordata));
    }
  };

export const GetOrderDollarCostThunk = (credit: number, continueToPaymentClick: boolean = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/getOrderDollarCost`;
    const result = await postJson<IOrderDollarCostResponse>(url, { credit, continueToPaymentClick }, token);
    if (result.success) {
      return result.data;
    }
    return {};
  };

// Get Tax Details for the order, this api only handles displaying the tax details on the checkout page
export const GetTaxThunk = (amount: number, signal?: AbortSignal) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { creditCards } = getState().subscription;
    const card = creditCards.find((c) => c.defaultCard);
    const { token } = getState().auth;
    const orders = getOrders(getState());
    const url = `${API_URL}/orders/calculateTax`;
    const org = getState().account?.org;
    const orgEmail = org?.email;
    const state = card?.address_state;
    const zipCode = card?.address_zip;
    if (!orgEmail || !state || !zipCode) {
      return {};
    }
    const result = await postJson<IGetTaxResponse>(url, { orgEmail, amount, state, zipCode }, token, signal);
    if (result.success) {
      const { salesTaxAmount, salesTaxRate } = result.data.taxDetails;
      dispatch(SetTaxDetailsAction({ _id: orders.activeOrder._id, salesTaxAmount, salesTaxRate }));
      return result.data.taxDetails;
    }
    return {};
  };

export const SaveOrderThunk = (templateId?: string, deliveryDate?: string, orderData?: IOrder, bundleType?: string, pricePerFoot?: number, isEstimate = false, saveEstimateClicked = false, projectDetailsSubmit = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/orders/saveOrder`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;

    const orders = getOrders(getState());
    let data = {};
    if (!_ADMIN_ && !projectDetailsSubmit) {
      const { rasterizationSelected } = getState().project;
      if (orderData) {
        // Save estimate when go to project details page and click go to checkout
        const selectedAddOns = orderData.addOns.map((del) => ({ _id: del.id, is3D: del.is3D }));

        data = {
          oid: orderData._id,
          orgId,
          pricePerFoot,
          salesTaxAmount: orderData.salesTaxAmount, // need this to display sales tax in the estimate pdf
          templateId: orderData.cadTemplate,
          currentUserTime: moment(new Date()).format(),
          bundles: [orderData.bundleId],
          deliverablesList: orderData.deliverablesList,
          contourMajr: orderData.topoIntervalMajor,
          contourMinr: orderData.topoIntervalMinor,
          selectedAddOns,
          density: orderData.density,
          deliverySpeed: orderData.deliverySpeed,
          referenceId: orderData.referenceId,
          isEstimate: orderData.isEstimate,
          saveEstimateClicked,
          rasterizationRequest: rasterizationSelected,
          projectType: bundleType,
        };
      } else {
        // Save estimate click, Place order click
        const { selectedBundle, newCustomBundle, additionalInstructions } = getState().bundle;
        const { adjustedOrderAcres } = selectedBundle;
        const bundleId = newCustomBundle ? newCustomBundle._id : selectedBundle._id;
        const additionalDeliverables = getState().bundle.selectedAdditionals.map((del) => ({ _id: del._id, is3D: del.is3D }));
        const bundleDeliverables = getState().bundle.bundleDeliverables.map((del) => del._id);
        data = {
          oid: orders.activeOrder._id,
          orgId,
          pricePerFoot,
          salesTaxAmount: orders.activeOrder.salesTaxAmount, // need this to display sales tax in the estimate pdf
          templateId,
          currentUserTime: moment(new Date()).format(),
          deliveryEstimate: deliveryDate,
          bundles: [bundleId],
          deliverablesList: bundleDeliverables,
          selectedAddOns: additionalDeliverables,
          contourMajr: getState().bundle.topoMajr,
          contourMinr: getState().bundle.topoMinr,
          adjustedOrderAcres,
          density: getState().bundle.density,
          deliverySpeed: getState().bundle.deliverySpeed,
          referenceId: orders.activeOrder.referenceId,
          isEstimate,
          saveEstimateClicked,
          rasterizationRequest: rasterizationSelected,
          projectType: bundleType,
          ...(additionalInstructions && { additionalInstructions }),
        };
      }
    } else if (projectDetailsSubmit) {
      data = { ...orders.activeOrder, projectDetailsSubmit, pricePerFoot };
    } else {
      // Place order in admin portal
      const { tifFiles, lasFiles, type } = getState().project.project;
      let defaultBundle = '';
      if (type === 'utilities') {
        defaultBundle = 'Underground';
      } else {
        const tifFilesUploaded = tifFiles && tifFiles.length > 0;
        const lasFilesUploaded = lasFiles && lasFiles.length > 0;
        if (tifFilesUploaded && !lasFilesUploaded) {
          defaultBundle = '2D';
        } else if (!tifFilesUploaded && lasFilesUploaded) {
          defaultBundle = 'Topo';
        } else if (tifFilesUploaded && lasFilesUploaded) {
          defaultBundle = '2D + Topo';
        }
      }

      data = {
        oid: orders.activeOrder._id,
        orgId,
        pricePerFoot,
        ...(defaultBundle && { defaultBundle }),
        deliverySpeed: getState().bundle.deliverySpeed,
        currentUserTime: moment(new Date()).format(),
        projectType: bundleType,
      };
    }

    dispatch(CreateOrderStartAction());
    const result = await postJson<IPlaceOrderResponse>(url, data, token);

    if (!result.success) {
      dispatch(CreateOrderFailureAction());
      return {};
    }

    return result;
  };

export const SaveEstimateThunk = (templateId?: string, deliveryDate?: string, orderData?: IOrder, bundleType?: string, pricePerFoot?: number, isEstimate = false, saveEstimateClicked = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const result = await SaveOrderThunk(templateId, deliveryDate, orderData, bundleType, pricePerFoot, isEstimate, saveEstimateClicked)(dispatch, getState);
    await dispatch(UpdateOrderAction(result.data?.order));
    dispatch(SetOrderLoadingAction(false));
    return result;
  };

export const PlaceOrderThunk = (templateId?: string, deliveryDate?: string, bundleType?: string, pricePerFoot?: number, projectDetailsSubmit = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orders = getOrders(getState());
    let result;
    if (projectDetailsSubmit) {
      result = await SaveOrderThunk(undefined, undefined, undefined, bundleType, pricePerFoot, undefined, false, projectDetailsSubmit)(dispatch, getState);
    } else {
      result = await SaveOrderThunk(templateId, deliveryDate, undefined, bundleType, pricePerFoot, false, undefined)(dispatch, getState);
    }
    const placedOrder = { ...orders.activeOrder };
    dispatch(CreateOrderSuccessAction(result.data.newOrder));
    dispatch(UpdateOrderAction({ ...placedOrder, orderStatus: 1 }));
    dispatch(SetPlaceOrderSuccessAction(true));
    if (result.data.subscriptionChange) {
      await GetOrgSubscriptionThunk()(dispatch, getState);
    }

    return result;
  };

export const SubmitCustomOrderThunk = (customInstructions?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/orders/saveOrder`;
    const { token } = getState().auth;
    const { rasterizationSelected } = getState().project;
    const orders = getOrders(getState());

    const data = {
      oid: orders.activeOrder._id,
      rasterizationRequest: rasterizationSelected,
      ...(customInstructions?.trim() !== '' && { customInstructions }),
    };

    dispatch(CreateOrderStartAction());
    const result = await postJson<IPlaceOrderResponse>(url, data, token);
    if (!result.success) {
      dispatch(CreateOrderFailureAction());
      return {};
    }

    const placedOrder = { ...orders.activeOrder };

    if (result.data.newOrder) {
      dispatch(CreateOrderSuccessAction(result.data.newOrder));
      dispatch(UpdateOrderAction({ ...placedOrder, orderStatus: 1 }));
    }

    dispatch(SetPlaceOrderSuccessAction(true));

    return result;
  };

export const ReprocessOrderThunk = (orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/orders/reprocessOrder`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;

    const data = { orderId, orgId };
    const result = await postJson<IOrder>(url, data, token);
    const order = getState().order.list.find((o) => o._id === orderId);
    if (!result.success) return result;

    dispatch(UpdateOrderAction({ ...order, orderStatus: 1 }));
    return result;
  };

export const UpdateOrder = (order: IOrder) => (dispatch: Dispatch) => dispatch(UpdateOrderAction(order));

export const UpdateOrderStatusThunk = (orderId: string, sid: number) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/orders/updateOrderStatus/${orderId}`;
    const { token } = getState().auth;
    dispatch(UpdateOrderStatusStartAction());
    const result = await patchJson<IOrder>(url, { sid }, token);

    if (result.success) {
      if (sid === 8) {
        const { order, creditAdjustments, totalAdjustment } = result.data.updatedOrder;
        order.totalAdjustment = totalAdjustment;
        order.creditAdjustments = creditAdjustments;
        dispatch(CreditAdjustmentSuccessAction(order));
        dispatch(UpdateOrderStatusSuccessAction(order));
      } else {
        dispatch(UpdateOrderStatusSuccessAction(result.data));
      }
    } else {
      dispatch(UpdateOrderStatusFailureAction(result.message));
    }

    return result;
  };

export const UpdateOrderCadFiles = (cadFile: ICadFile) => (dispatch: Dispatch) => dispatch(UpdateOrderCadFilesAction(cadFile));

export const RemoveEstimateThunk = (orderId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/orders/${orderId}`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;
    const data = {
      orgId,
      removeEstimate: true,
    };

    const result = await patchJson<IOrder>(url, data, token);
    if (result.success) {
      dispatch(EditOrderSuccessAction(result.data.order));
    }

    return result;
  };

export const SaveCustomBundleThunk = (customBundleName: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/customBundle`;
    const { token } = getState().auth;
    const { org } = getState().account;
    const { selectedBundle, bundleDeliverables, selectedAdditionals } = getState().bundle;
    const deliverablesList: { _id: string, is3D: boolean }[] = [];
    const addOns: { _id: string, is3D: boolean }[] = [];
    bundleDeliverables.forEach((d) => {
      deliverablesList.push({
        _id: d._id,
        is3D: d.is3D,
      });
    });
    selectedAdditionals.forEach((d) => {
      addOns.push({
        _id: d._id,
        is3D: d.is3D,
      });
    });

    const data = {
      name: customBundleName,
      ownerOrganization: org?._id,
      bundleId: selectedBundle._id,
      deliverablesList,
      addOns,
    };

    const result = await postJson<{ customBundle: IBundle }>(url, data, token);

    if (result.success) {
      const { customBundle } = result.data;
      customBundle.adjustedOrderAcres = selectedBundle.adjustedOrderAcres;
      dispatch(SetNewCustomBundleAction(customBundle));

      const layerList = selectedAdditionals.reduce((acc: string, deliverable: IDeliverable) => acc + (acc ? ', ' : '') + deliverable.name, '');
      window?.pendo?.track('Custom Bundle Saved', {
        bundleName: customBundle.name,
        layerList,
      });
    }

    return result;
  };

export const UpdateOrderDeliverableFiles = (deliverableFile: IDeliverableFile) => (dispatch: Dispatch) => dispatch(UpdateOrderDeliverableFilesAction(deliverableFile));

export const UpdateOrderLasFiles = (lasFile: ILasFile) => (dispatch: Dispatch) => dispatch(UpdateOrderLasFilesAction(lasFile));

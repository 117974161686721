// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useMemo } from 'react';
import { Typography, TableRow, TableCell } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import downloadIcon from 'Assets/download-icon.png';
import moment from 'moment-timezone';
import { GetProjectDetailsRoute } from 'Utils/routes';
import { Link as RouterLink } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from 'Features/order/PDF';
import { formatValue } from 'Utils/deliverables';
import { useAppSelector } from 'Hooks';
import { WideLightTooltip } from 'Components/Tooltip';
import OrderSummary from './orderSummary';
import stylesDef from './styles';

moment.tz.setDefault('America/New_York');

interface ComponentProps {
  order: IOrder;
}

export const OrderListItem: React.FC<ComponentProps> = ({ order }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const project = useAppSelector((state) => state.projectList.list?.find((item: IProject) => item._id === order.ownerProject));

  const costPerAcre = useAppSelector((state) => state.account.org?.costPerAcre);

  const imageryProvider = project?.imageryRequest?.provider;
  const projectName = project?.name;
  const automatePlanType = useAppSelector((state) => state.subscription.automateSubscription?.priceName);
  const { orgTeams } = useAppSelector((state) => state.account);
  const templates = useAppSelector((state) => state.cadTemplate.list);

  const epsgCode = project?.tifFiles?.[0]?.epsg;
  const team = orgTeams?.find((t) => t._id === order.team);

  const pdfData = useMemo(() => {
    const { addOns, deliverables, referenceId, acreage, creditAcreage, costToCustomer, paidForThisProject, middlePoint, cadTemplate, topoIntervalMajor, topoIntervalMinor,
      orderDate, deliveryDate, bundleName, bundleAcreAdjustmentFactor, density, deliverySpeed, ownerEmail, submittedByAwAdmin, salesTaxAmount, expressAICreditAcreage, pricePerFoot, additionalInstructions } = order;
    const imageryCreditCost = order.imageryCreditCost || 0;
    const imageryDollarCost = order.imageryDollarCost || 0;
    const rasterizationCreditCost = order.rasterizationCreditCost || 0;
    let addOnsInAcres = false;
    // If all add-ons include an acres field, it indicates that the user paid for these add-ons in acres
    // Any new orders include this field, which means if order include imagery request or raster request, the order include acres field
    if (addOns?.every((addOn) => addOn.hasOwnProperty('acres'))) {
      addOnsInAcres = true;
    }
    const deliverablesCredits = addOns.reduce((sum, addOn) => {
      if (addOn.addOnType === 'deliverable') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const deliverablesCreditsString = addOnsInAcres ? `${deliverablesCredits.toFixed(2)} credits` : `$${formatValue(deliverablesCredits)}`;
    const layersCredits = addOns.reduce((sum, addOn) => {
      if (addOn.addOnType === 'layer') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const layersCreditsString = addOnsInAcres ? `${layersCredits.toFixed(2)} credits` : `$${formatValue(layersCredits)}`;
    const addOnsCreditsString = addOnsInAcres ? `${(rasterizationCreditCost + imageryCreditCost + deliverablesCredits + layersCredits).toFixed(2)} credits` : `$${formatValue(imageryDollarCost + deliverablesCredits + layersCredits)}`;

    let chargedCredits;
    if (bundleName === 'A la carte') {
      chargedCredits = 0;
    } else if (bundleName === '2D Only - Express AI') {
      chargedCredits = expressAICreditAcreage;
    } else {
      chargedCredits = (creditAcreage - (addOnsInAcres ? layersCredits + deliverablesCredits + imageryCreditCost + rasterizationCreditCost : 0))?.toFixed(2);
    }

    return ({
      type: 'Invoice',
      automatePlanType,
      submitter: submittedByAwAdmin ? 'AirWorks Admin' : (ownerEmail ? ownerEmail : 'N/A'),
      projectName,
      referenceId,
      team: team?.name,
      acreage,
      salesTaxAmount,
      middlePoint: `${middlePoint?.place}, ${middlePoint?.region} ${middlePoint?.postcode}`,
      coordinates: `${middlePoint?.longitude.toFixed(5)}, ${middlePoint?.latitude.toFixed(5)}`,
      cadTemplate: templates.find((temp) => temp._id === cadTemplate)?.name,
      imageryProvider,
      imageryCreditCost: imageryCreditCost?.toFixed(2),
      rasterizationCreditCost: rasterizationCreditCost?.toFixed(2),
      crs: epsgCode && `EPSG: ${epsgCode}`,
      topoMajr: topoIntervalMajor,
      topoMinr: topoIntervalMinor,
      orderDate: orderDate ? moment(orderDate).format('MMMM Do YYYY') : 'N/A',
      deliveryDate: deliveryDate ? moment(deliveryDate).format('MMMM Do YYYY') : 'N/A',
      selectedBundle: { name: bundleName, acreAdjustmentFactor: bundleAcreAdjustmentFactor, density, deliverySpeed },
      costPerAcre,
      bundleDeliverables: deliverables,
      selectedAdditionals: addOns,
      chargedCredits,
      totalCreditsCharged: (bundleName === '2D Only - Express AI' ? chargedCredits : creditAcreage)?.toFixed(2),
      deliverablesCreditsString,
      layersCreditsString,
      addOnsCreditsString,
      totalAdditionalCharged: paidForThisProject?.toFixed(2),
      totalCostForProject: costToCustomer?.toFixed(2),
      pricePerFoot,
      additionalInstructions,
      bundleType: project?.type,
      kmlLength: order.kmlLength,
    });
  }, []);

  return (
    <TableRow key={order._id}>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        {order.name}
      </TableCell>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        {order.referenceId || "N/A"}
      </TableCell>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        {team?.name || "N/A"}
      </TableCell>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        {/* If orderDate is undefined display N/A */}
        {order.orderDate
          ? moment(order.orderDate).format("MMMM D, YYYY")
          : "N/A"}
      </TableCell>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        {project.type === 'utilities' ? "N/A" : order.acreage.toFixed(2)}
      </TableCell>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        {project.type !== 'utilities' ? "N/A" : order.kmlLength?.toFixed(2)}
      </TableCell>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        <Box sx={styles.info}>
          {(order.expressAICreditAcreage ? order.expressAICreditAcreage : order.creditAcreage).toFixed(2)}
          <WideLightTooltip
            placement="bottom"
            title={<OrderSummary order={order} />}
          >
            <InfoIcon sx={styles.icon} />
          </WideLightTooltip>
        </Box>
      </TableCell>
      <TableCell sx={styles.tableCell} component="th" scope="row">
        <Box sx={styles.info}>
          {order.costToCustomer ? `$${order.costToCustomer.toFixed(2)}` : "N/A"}
          <WideLightTooltip
            placement="bottom"
            interactive
            title={
              <Box>
                <Box sx={styles.divGrid}>
                  <Typography variant="body1" sx={styles.text}>
                    Project cost
                  </Typography>
                  <Typography sx={styles.text} variant="body1">
                    {order.costToCustomer >= 0
                      ? `$${order.costToCustomer.toFixed(2)}`
                      : "N/A"}
                  </Typography>
                </Box>
                {order?.salesTaxAmount > 0 && (
                  <Box sx={styles.divGrid}>
                    <Typography variant="body1" sx={styles.text}>
                      Sales tax
                    </Typography>
                    <Typography sx={styles.text} variant="body1">
                      {`$${order.salesTaxAmount.toFixed(2)}`}
                    </Typography>
                  </Box>
                )}
                <Box sx={styles.divGrid}>
                  <Typography variant="body1" sx={styles.text}>
                    Additional cost
                  </Typography>
                  <Typography sx={styles.text} variant="body1">
                    {order.paidForThisProject >= 0
                      ? `$${order.paidForThisProject.toFixed(2)}`
                      : "N/A"}
                  </Typography>
                </Box>
              </Box>
            }
          >
            <InfoIcon sx={styles.icon} />
          </WideLightTooltip>
        </Box>
      </TableCell>
      <TableCell sx={styles.iconTableCell} component="th" scope="row">
        <PDFDownloadLink
          document={<PDF data={pdfData} />}
          fileName={`${projectName}_invoice.pdf`}
        >
          {({ blob, url, loading }) =>
            !loading && (
              <Box
                onClick={() => {
                  window?.pendo?.track("Invoice downloaded", {
                    projectId: order?.ownerProject,
                  });
                }}
                aria-hidden="true"
              >
                <img src={downloadIcon} alt="download" />
              </Box>
            )
          }
        </PDFDownloadLink>
      </TableCell>
      {!_ADMIN_ && (
        <TableCell sx={styles.tableCell} component="th" scope="row">
          <RouterLink
            to={GetProjectDetailsRoute(order.ownerProject)}
            css={styles.routerLink}
            title="View Project"
          >
            <Typography variant="h6">View Project</Typography>
          </RouterLink>
        </TableCell>
      )}
    </TableRow>
  );
};

export default OrderListItem;

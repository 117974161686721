// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Dialog, DialogActions, Button, Typography, TextField, CircularProgress, Snackbar, Alert } from '@mui/material/';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch } from 'Hooks';
import { SaveCustomBundleThunk } from 'Features/order/orderThunk';
import stylesDef from './styles';

interface SaveLayerSelectionProps {
  showSaveLayerSelection: boolean;
  setShowSaveLayerSelection: (show: boolean) => void;
  setCustomBundleName: (name: string) => void;
}

export const SaveLayerSelection = ({ showSaveLayerSelection, setShowSaveLayerSelection, setCustomBundleName }: SaveLayerSelectionProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const [bundleName, setBundleName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [failedResultMessage, setFailedResultMessage] = useState('');

  const handleBundleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBundleName(event.target.value);
  };

  const onClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  const handleClose = () => {
    setShowSaveLayerSelection(false);
    setBundleName('');
  };

  const onSave = async () => {
    setCustomBundleName(bundleName);
    setLoading(true);
    const saveResult = await dispatch(SaveCustomBundleThunk(bundleName));
    setLoading(false);
    if (saveResult.success) {
      setShowSaveLayerSelection(false);
      setBundleName('');
    } else {
      setShowError(true);
      setFailedResultMessage(saveResult.message);
    }
  };

  return (
    <Dialog open={showSaveLayerSelection} onClose={onClose}>
      <Snackbar open={showError} autoHideDuration={5000} onClose={() => setShowError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" sx={styles.error} color="error">{failedResultMessage}</Alert>
      </Snackbar>
      <Box sx={styles.container}>
        <Typography variant="h2">Save Layer Selection as a Custom Bundle</Typography>
        <Typography variant="body1" sx={styles.text}>Save your selected layers as a custom bundle for easy access in future orders. This allows you to quickly reuse your preferred setup without having to reselect each layer. Choose a name and click on Save.</Typography>
        <TextField label="Name" variant="outlined" sx={styles.inputField} value={bundleName} onChange={handleBundleNameChange} />
        <DialogActions sx={styles.buttons}>
          <Button onClick={handleClose} sx={styles.dismissButton}>CLOSE</Button>
          <Button variant="contained" color="primary" disabled={!bundleName || loading} sx={styles.saveButton} onClick={onSave}>
            {loading ? <CircularProgress size={24} /> : 'SAVE'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SaveLayerSelection;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import LayerListItem from '../LayerListItem';
import stylesDef from './styles';

interface ParentLayerItemProps {
  items: IDeliverable[];
  activeOrder: any;
  ugAlaCarte: any;
  ugAlaCarteBoolean: boolean;
  onAddOnsClick: (selectedList: { data: IDeliverable, checked: boolean, typeFactor3D?:boolean }[]) => void;
  isUnavailable: (item: IDeliverable) => boolean;
}

const ParentLayerItem = ({ items, activeOrder, ugAlaCarte, ugAlaCarteBoolean, onAddOnsClick, isUnavailable }: ParentLayerItemProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const childrenWithParent = items.filter((item) => item.dependencies.length !== 0);
  const parentOfChildren = items.reduce((acc, parent) => {
    const childrenArray = childrenWithParent.filter(child => child.dependencies.includes(parent._id));
    if (childrenArray.length > 0) {
      acc.push({ parent, childrenArray });
    }
    return acc;
  }, []);

  const childrenWithoutParent = items.filter((item) => item.dependencies.length === 0 && !parentOfChildren.find((parent) => parent.parent._id === item._id));

  if (parentOfChildren.length > 0) {
    return (
      <Box>
        {childrenWithoutParent.map((item) => (
          <LayerListItem 
            key={item._id}
            item={item}
            activeOrder={activeOrder}
            ugAlaCarte={ugAlaCarte}
            ugAlaCarteBoolean={ugAlaCarteBoolean}
            onAddOnsClick={onAddOnsClick}
            isUnavailable={isUnavailable(item)}
          />
        ))}
        {parentOfChildren.map((item) => (
          <>
            <LayerListItem
              item={item.parent}
              activeOrder={activeOrder}
              ugAlaCarte={ugAlaCarte}
              ugAlaCarteBoolean={ugAlaCarteBoolean}
              onAddOnsClick={onAddOnsClick}
              isUnavailable={isUnavailable(item.parent)}
            />
            <Box sx={styles.childrenArray}>
              {
                item.childrenArray.map((child:any) => (
                  <LayerListItem
                    key={child._id}
                    item={child}
                    activeOrder={activeOrder}
                    ugAlaCarte={ugAlaCarte}
                    ugAlaCarteBoolean={ugAlaCarteBoolean}
                    onAddOnsClick={onAddOnsClick}
                    isUnavailable={isUnavailable(child)}
                  />
                ))
              }
            </Box>
          </>
        ))}
      </Box>
    );
  }
  return (
    <Box>
      {items.map((item) => (
        <LayerListItem 
          key={item._id}
          item={item}
          activeOrder={activeOrder}
          ugAlaCarte={ugAlaCarte}
          ugAlaCarteBoolean={ugAlaCarteBoolean}
          onAddOnsClick={onAddOnsClick}
          isUnavailable={isUnavailable(item)}
        />
      ))}
    </Box>
  );
};

export default ParentLayerItem;

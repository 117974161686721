// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  dialogContainer: {
    padding: theme.spacing(2),
  },
  cancelButton: {
    color: '#021E40',
    opacity: '50%',
    fontSize: theme.spacing(2),
  },
  deleteButton: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2),
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '16px 0px',
  },
  dialogTitleText: {
    fontWeight: '400',
  },
  errorIcon: {
    color: '#FAA738',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
});

export default styles;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { matchPath, generatePath } from 'react-router';

export const ROOT_ROUTE = '/';
export const FORGOT_PASSWORD_ROUTE = '/forgot';
export const SIGN_UP_ROUTE = '/signUp';
export const SIGN_UP_SUCCESS = '/signUpSuccess';
export const RESET_PASSWORD_ROUTE = '/verifyToken/:token';
export const EXPIRED_TOKEN_ROUTE = '/expiredToken';

export const PROJECTS_ROUTE = '/projects';
export const PROJECT_DETAILS_ROUTE = '/projects/:id/details';
export const GetProjectDetailsRoute = (projectId: string) => generatePath(PROJECT_DETAILS_ROUTE, { id: projectId });
export const PROJECT_FILES_ROUTE = '/projects/:id/files';
export const GetProjectFilesRoute = (projectId: string) => generatePath(PROJECT_FILES_ROUTE, { id: projectId });
export const PROJECT_3DVIEWER_ROUTE = '/projects/:id/3d';
export const GetProject3DViewerRoute = (projectId: string) => generatePath(PROJECT_3DVIEWER_ROUTE, { id: projectId });

export const ACCOUNT_GENERAL_ROUTE = '/account/general';
export const CAD_TEMPLATES_ROUTE = '/account/cadTemplates/:id';
export const GetTemplatesRoute = (id: string) => generatePath(CAD_TEMPLATES_ROUTE, { id });
export const CAD_TEMPLATE_ROUTE = '/account/cadTemplates/:templateId?';
export const GetTemplateRoute = (templateId: string) => generatePath(CAD_TEMPLATE_ROUTE, { templateId });
export const ACCOUNT_BILLING_ROUTE = '/account/billing';
export const ACCOUNT_PASSWORD_ROUTE = '/account/password';
export const ORDER_HISTORY_ROUTE = '/account/orderHistory';
export const ACCOUNT_PLAN_USAGE = '/account/planUsage';
export const USER_MANAGEMENT_ROUTE = '/account/userManagement';
export const TEAMS_ROUTE = '/account/teams';
export const CUSTOM_BUNDLES_ROUTE = '/account/customBundles';

export const ADMIN_ROUTE = '/admin';
export const ADMIN_OPS_SETTINGS_ROUTE = '/admin/opsSettings';
export const ADMIN_PRICING_TIERS_ROUTE = '/admin/pricing';
export const ADMIN_GENERAL_ROUTE = '/admin/:orgId/general';
export const GetAdminGeneralRoute = (orgId: string) => generatePath(ADMIN_GENERAL_ROUTE, { orgId });
export const ADMIN_PROJECTS_ROUTE = '/admin/:orgId/projects';
export const GetAdminProjectsRoute = (orgId: string) => generatePath(ADMIN_PROJECTS_ROUTE, { orgId });
export const ADMIN_PROJECT_ROUTE = '/admin/:orgId/projects/:projectId';
export const GetAdminProjectRoute = (orgId: string, projectId: string) => generatePath(ADMIN_PROJECT_ROUTE, { orgId, projectId });
export const ADMIN_SUBSCRIPTION_ROUTE = '/admin/:orgId/subscription';
export const GetAdminSubscriptionRoute = (orgId: string) => generatePath(ADMIN_SUBSCRIPTION_ROUTE, { orgId });
export const ADMIN_USERS_ROUTE = '/admin/:orgId/users';
export const GetAdminUsersRoute = (orgId: string) => generatePath(ADMIN_USERS_ROUTE, { orgId });
export const ADMIN_USER_ROUTE = '/admin/:orgId/users/:userId?';
export const GetAdminUserRoute = (orgId: string, userId: string) => generatePath(ADMIN_USER_ROUTE, { orgId, userId });
export const ADMIN_ORG_PLAN_USAGE_ROUTE = '/admin/:orgId/planUsage';
export const GetAdminOrgPlanUsageRoute = (orgId: string) => generatePath(ADMIN_ORG_PLAN_USAGE_ROUTE, { orgId });
export const ADMIN_ORDER_HISTORY_ROUTE = '/admin/:orgId/orderHistory';
export const GetAdminOrderHistoryRoute = (orgId: string) => generatePath(ADMIN_ORDER_HISTORY_ROUTE, { orgId });
export const ADMIN_CAD_TEMPLATES_ROUTE = '/admin/:orgId/cadTemplates/:id?';
export const GetAdminCadTemplatesRoute = (orgId: string, id: string) => generatePath(ADMIN_CAD_TEMPLATES_ROUTE, { orgId, id });
export const ADMIN_CAD_TEMPLATE_ROUTE = '/admin/:orgId/cadTemplates/:templateId?';
export const GetAdminCadTemplateRoute = (orgId: string, templateId: string) => generatePath(ADMIN_CAD_TEMPLATE_ROUTE, { orgId, templateId });
export const ADMIN_CUSTOM_BUNDLES_ROUTE = '/admin/:orgId/customBundles';
export const GetAdminCustomBundlesRoute = (orgId: string) => generatePath(ADMIN_CUSTOM_BUNDLES_ROUTE, { orgId });

export const IsProjectListPath = (currentPath: string) => matchPath(currentPath, PROJECTS_ROUTE);

export const IsProjectPath = (currentPath: string) => (
  matchPath({ path: PROJECT_DETAILS_ROUTE }, currentPath)
  || matchPath({ path: PROJECT_FILES_ROUTE }, currentPath)
  || matchPath({ path: PROJECT_3DVIEWER_ROUTE }, currentPath)
);

export const IsAccountPath = (currentPath: string) => (
  matchPath({ path: ACCOUNT_GENERAL_ROUTE }, currentPath)
  || matchPath({ path: CAD_TEMPLATES_ROUTE }, currentPath)
  || matchPath({ path: ACCOUNT_BILLING_ROUTE }, currentPath)
  || matchPath({ path: ORDER_HISTORY_ROUTE }, currentPath)
  || matchPath({ path: ACCOUNT_PASSWORD_ROUTE }, currentPath)
  || matchPath({ path: ACCOUNT_PLAN_USAGE }, currentPath)
  || matchPath({ path: USER_MANAGEMENT_ROUTE }, currentPath)
  || matchPath({ path: TEAMS_ROUTE }, currentPath)
  || matchPath({ path: CUSTOM_BUNDLES_ROUTE }, currentPath)
);

export const IsAdminListPath = (currentPath: string) => (
  matchPath({ path: ADMIN_ROUTE }, currentPath)
  || matchPath({ path: ADMIN_OPS_SETTINGS_ROUTE }, currentPath)
  || matchPath({ path: ADMIN_PRICING_TIERS_ROUTE }, currentPath)
);

export const IsAdminPath = (currentPath: string) => (
  matchPath({ path: ADMIN_GENERAL_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_PROJECTS_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_SUBSCRIPTION_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_PROJECT_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_USERS_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_USER_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_ORG_PLAN_USAGE_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_ORDER_HISTORY_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_CAD_TEMPLATE_ROUTE }, currentPath)
|| matchPath({ path: ADMIN_CUSTOM_BUNDLES_ROUTE }, currentPath)
);

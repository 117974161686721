// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: theme.spacing(8),
  },
  customBundlesTitle: {
    fontWeight: '600',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '800px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(3),
  },
  noBundlesText: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  finishedIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    color: '#01A1DD',
  },
  listItemIcon: {
    minWidth: 0,
  },
  bundleListItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
});

export default styles;

// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { GetAdminGeneralRoute, GetAdminProjectsRoute, GetAdminSubscriptionRoute, GetAdminUsersRoute, GetAdminOrgPlanUsageRoute, GetAdminOrderHistoryRoute, GetAdminCadTemplatesRoute, ADMIN_GENERAL_ROUTE, ADMIN_PROJECTS_ROUTE, ADMIN_SUBSCRIPTION_ROUTE, ADMIN_USERS_ROUTE, ADMIN_ORG_PLAN_USAGE_ROUTE, ADMIN_ORDER_HISTORY_ROUTE, ADMIN_CAD_TEMPLATES_ROUTE, GetAdminCustomBundlesRoute } from 'Utils/routes';
import { Box } from '@mui/system';
import { useAppSelector } from 'Hooks';
import stylesDef from './styles';

export const AdminToolbar = () => {
  const styles = stylesDef();
  const location = useLocation();
  const currentPath = location.pathname;

  const { orgId } = useAppSelector((state) => state.admin);
  const defaultTemplate = useAppSelector((state) => state.admin?.org?.defaultTemplate);

  function getActiveTabId(pathname: string) {
    if (matchPath({ path: ADMIN_GENERAL_ROUTE }, pathname)) return 1;
    if (matchPath({ path: ADMIN_PROJECTS_ROUTE }, pathname)) return 2;
    if (matchPath({ path: ADMIN_SUBSCRIPTION_ROUTE }, pathname)) return 3;
    if (matchPath({ path: ADMIN_USERS_ROUTE }, pathname)) return 4;
    if (matchPath({ path: ADMIN_ORG_PLAN_USAGE_ROUTE }, pathname)) return 5;
    if (matchPath({ path: ADMIN_ORDER_HISTORY_ROUTE }, pathname)) return 6;
    if (matchPath({ path: ADMIN_CAD_TEMPLATES_ROUTE }, pathname)) return 7;
    return null;
  }

  const activeTabId = getActiveTabId(currentPath);

  return (orgId && (
    <Box>
      <Tabs indicatorColor="primary" value={activeTabId}>
        <Tab
          component={Link}
          label="General"
          value={1}
          to={GetAdminGeneralRoute(orgId)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
        <Tab
          component={Link}
          label="Projects"
          value={2}
          to={GetAdminProjectsRoute(orgId)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
        <Tab
          component={Link}
          label="Bundles"
          value={8}
          to={GetAdminCustomBundlesRoute(orgId)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
        <Tab
          component={Link}
          label="Subscription"
          value={3}
          to={GetAdminSubscriptionRoute(orgId)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
        <Tab
          component={Link}
          label="Users"
          value={4}
          to={GetAdminUsersRoute(orgId)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
        <Tab
          component={Link}
          label="Plan Usage"
          value={5}
          to={GetAdminOrgPlanUsageRoute(orgId)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
        <Tab
          component={Link}
          label="Order History"
          value={6}
          to={GetAdminOrderHistoryRoute(orgId)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
        <Tab
          component={Link}
          label="CAD Templates"
          value={7}
          to={GetAdminCadTemplatesRoute(orgId, defaultTemplate)}
          sx={{
            wrapper: styles.tabLabel,
          }}
        />
      </Tabs>
    </Box>
  ));
};

export default AdminToolbar;

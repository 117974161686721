// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  listItemIcon: {
    minWidth: 0,
  },
  bundleListItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  finishedIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    color: '#01A1DD',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(3),
  },
  customBundleItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  bundleTitle: {
    fontWeight: '600',
  },
  bundleDate: {
    fontSize: '14px',
    fontWeight: '500',
  },
  contentLists: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
  },
  contentList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentCounter: {
    fontWeight: '600',
    fontSize: '20px',
  },
  contentCounterText: {
    fontWeight: '500',
    fontSize: '14px',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginLeft: '25px',
  },
  deleteIcon: {
    color: '#788898',
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  expandedContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
  },
  expandedColumn: {
    width: '50%',
  },
});

export default styles;

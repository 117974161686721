// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  container: {
    padding: '30px',
  },
  text: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    gap: theme.spacing(1),
  },
  dismissButton: {
    color: '#B1A9A7',
    width: '80px',
    height: '40px',
    fontSize: '16px',
  },
  saveButton: {
    width: '80px',
    height: '40px',
    fontSize: '16px',
  },
  inputField: {
    margin: '20px 0',
    '& .MuiInputBase-input': {
      width: '530px',
      height: '45px',
      padding: '4px',
      fontSize: '1rem',
    },
  },
  error: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;

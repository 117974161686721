// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  finishedIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    color: '#01A1DD',
  },
  listItemIcon: {
    minWidth: 0,
  },
  bundleListItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  orderDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 5fr',
    alignItems: 'center',
    justifySelf: 'left',
    '&>*:nth-child(3)': {
      textAlign: 'right',
      justifySelf: 'right',
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  orderNumber: {
    padding: '6px',
  },
  statusText: {
    padding: '9px',
  },
  gridChild: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(3),
    alignItems: 'center',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      justifySelf: 'right',
      paddingRight: '10px',
    },
  },
  orderInfoWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
  creditAdjustmentItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    minHeight: '40px',
    height: 'fit-content',
  },
  adjustmentNumber: {
    paddingRight: '15px',
  },
  adjustmentList: {
    marginTop: '0px',
  },
  cadFileList: {
    display: 'flex',
    gridTemplateColumns: '1fr auto auto',
    gridColumnGap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '500px',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      justifySelf: 'right',
    },
  },
  cadFileName: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(1),
  },
  outputFilesWrapper: {
    width: '40%',
  },
  orderContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  downloadProgress: {
    position: 'relative',

  },
  deleteIcon: {
    fontSize: '20px',
  },
  fontWeight: {
    fontWeight: 300,
  },
  statusButton: {
    padding: '0px',
  },
  select: {
    fontWeight: 300,
  },
  itemWrapper: {
    marginBottom: '10px',
  },
  buttonGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    float: 'right',
  },
  editOrderButtonWrapper: {
    float: 'right',
  },
  inputTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emailText: {
    fontSize: '14px',
    padding: '5px',
  },
  layersAndDeliverablesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  layersAndDeliverablesTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  sectionDivider: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  addOnItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  addOnName: {
    textAlign: 'left',
    flexBasis: '50%',
    flexShrink: 1,
    wordWrap: 'break-word',
  },
  addOnAcres: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: '0.55rem',
  },
  doneIcon: {
    color: '#06a1d8',
  },
  arrowIcon: {
    color: 'dimgray',
    marginTop: '0.5rem',
  },
  totalCreditTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexBasis: '40%',
    marginTop: '0.75rem',
    fontSize: '1.1rem',
    whiteSpace: 'nowrap',
  },
  totalCredits: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: '0.55rem',
    marginTop: '0.75rem',
  },
  buttonContainer: {
    width: '110px',
    display: 'flex',
    justifyContent: 'center',
  },
  gdbButton: {
    padding: '8px 0',
  },
  warningFile: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  infoIcon: {
    fontSize: '20px',
    color: '#FAA738',
  },
  duplicateContainer: {
    display: 'flex',
    backgroundColor: '#FFF4E5',
    alignItems: 'center',
    gap: '10px',
    height: '40px',
    paddingLeft: '10px',
  },
  projectType: {
    textTransform: 'capitalize',
  },
  additionalInstructions: {
    textTransform: 'none',
  },
  additionalInstructionsText: {
    margin: '5px 0',
  },
  datePicker: {
    justifyContent: 'flex-end',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '0px',
      color: 'inherit',
      width: '47%',
    },
    '& .MuiOutlinedInput-root': {
      justifyContent: 'flex-end',
      padding: '0px',
      color: 'inherit',
    },
  },
  arrowDropDown: {
    padding: '0px',
  },
});

export default styles;

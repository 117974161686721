// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Typography, TableHead, Table, TableRow, TableBody, TableCell, Divider } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';
import exportIcon from 'Assets/export.png';
import orderListIcon from 'Assets/order_list_icon.png';
import InfoIcon from '@mui/icons-material/Info';
import { GetProjectDetailsRoute } from 'Utils/routes';
import { URL } from 'Config';
import moment from 'moment';
import LoadingSpinner from 'Components/LoadingSpinner';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { WideLightTooltip } from 'Components/Tooltip';
import SearchField from 'Components/SearchField';
import OrderListItem from './orderListItem';
import { SetLoadNewAction, SetKeywordFilterAction } from 'Features/projectList/projectListActions';
import { getCompletedOrders } from 'Features/order/orderSelectors';
import { GetOrdersThunk } from 'Features/order/orderThunk';
import { GetOrgTeamsThunk } from '../accountThunk';
import { GetOrgThunk } from 'Features/admin/adminThunk';
import { SetOrgIdAction } from 'Features/admin/adminActions';
import stylesDef from './styles';
import { GetProjectsThunk } from 'Features/projectList/projectListThunk';

export const OrderHistory = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => getCompletedOrders(state));
  const organization = useAppSelector((state) => (_ADMIN_ ? state.admin?.org?.companyName : state.account.org?.companyName));
  const { orgId } = useParams();
  const { orgTeams } = useAppSelector((state) => state.account);

  const headers = [
    { label: 'Order Name', key: 'orderName' },
    { label: 'Reference ID', key: 'referenceId' },
    { label: 'Team', key: 'team' },
    { label: 'Date', key: 'date' },
    { label: 'Orders Acres', key: 'orderAcres' },
    { label: 'Charged Credits', key: 'chargedAcres' },
    { label: 'Imagery Credits', key: 'imageryCreditCost' },
    { label: 'Point Cloud Rasterization', key: 'rasterizationCreditCost' },
    { label: 'Project Size', key: 'projectSize' },
    { label: 'Bundle', key: 'bundle' },
    { label: 'Density', key: 'density' },
    { label: 'Delivery Speed', key: 'deliverySpeed' },
    { label: 'Add ons', key: 'addOns' },
    { label: 'Adjusted Credits', key: 'adjustedCredits' },
    { label: 'Total Adjustment', key: 'totalAdjustment' },
    { label: 'Total Cost for Project', key: 'totalCost' },
    { label: 'Additional Charges', key: 'additionalCost' },
    ..._ADMIN_ ? [] : [{ label: 'Project Link', key: 'projectLink' }],
  ];
  const [showLoader, setShowLoader] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [orderList, setOrderList] = useState([]);

  // Load all projects on the Order History tab in order to display all processed orders for an organization
  useEffect(() => {
    if (_ADMIN_) dispatch(SetOrgIdAction(orgId));
    const loadData = async () => {
      setShowLoader(true);
      dispatch(SetLoadNewAction(true));
      dispatch(SetKeywordFilterAction(''));
      if (_ADMIN_) {
        await Promise.all([
          dispatch(GetOrgThunk(orgId)),
          dispatch(GetProjectsThunk(orgId, 0)),
        ]);
      } else {
        await dispatch(GetProjectsThunk('', 0));
      }
      await dispatch(GetOrgTeamsThunk());
      await dispatch(GetOrdersThunk(null, orgId));

      setShowLoader(false);
    };

    loadData();
  }, []);

  const prepareCsvData = () => {
    setCsvData(list.map((order) => {
      const team = orgTeams?.find((t) => t._id === order.team);
      let addOnsList;
      let adjustmentList;
      if (order.addOns.length > 0) {
        addOnsList = order.addOns.map((addOn) => `[${addOn.hasOwnProperty('acres') ? `${addOn.acres.toFixed(2)} ac, $${addOn.cost.toFixed(2)}` : `$${addOn.cost.toFixed(2)}`}] - ${addOn.name || addOn.addOnType}`).join(' \n');
      }
      if (order.creditAdjustments?.length > 0) {
        adjustmentList = order.creditAdjustments.map((adjsmnt) => `[${`${adjsmnt.adjustment >= 0 ? '+' : ''}${adjsmnt.adjustment.toFixed(2)} cr`}] - ${adjsmnt.reason}`).join(' \n');
      }

      const data = {
        orderName: order.name,
        referenceId: order.referenceId || 'N/A',
        team: team?.name || 'N/A',
        date: order.orderDate ? moment.utc(order.orderDate).local().format('MMMM D, YYYY') : 'N/A',
        orderAcres: order.acreage,
        // This includes addOn acres
        chargedAcres: order.creditAcreage,
        imageryCreditCost: order.imageryCreditCost ? `${order.imageryProvider} - ${order.imageryCreditCost} cr` : 'N/A',
        rasterizationCreditCost: order.rasterizationCreditCost ? `${order.rasterizationCreditCost} cr` : 'N/A',
        projectSize: order.acreage,
        bundle: order.bundleName,
        density: order.density,
        deliverySpeed: order.deliverySpeed,
        addOns: addOnsList || 'N/A',
        adjustedCredits: adjustmentList,
        totalAdjustment: order.adjustedAcres,
        totalCost: order.costToCustomer ? `$${order.costToCustomer.toFixed(2)}` : 'N/A',
        additionalCost: order.paidForThisProject ? `$${order.paidForThisProject.toFixed(2)}` : 'N/A',
      };
      if (!_ADMIN_) data.projectLink = URL + GetProjectDetailsRoute(order.ownerProject);
      return data;
    }));
    setFileName(`${organization}_orderhistory_${moment().format('YYYYMMDD')}.csv`);
  };

  useEffect(() => {
    prepareCsvData();
    setOrderList(list);
  }, [list]);

  const handleSearchChange = (e: string): void => {
    if (!e) {
      setOrderList(list);
      return;
    }
    const teams = orgTeams.filter(({ name }) => name.toLowerCase().includes(e.toLowerCase()));

    const filteredlist = list.filter(({ referenceId, team, name }) =>
      (referenceId && referenceId.toLowerCase().includes(e.toLowerCase())) ||
      (name && name.toLowerCase().includes(e.toLowerCase())) ||
      (team && teams.some(({ _id }) => _id === team)));

    setOrderList(filteredlist);
  };

  return (
    <LoadingSpinner loading={showLoader}>
      <Box sx={styles.pageWrapper}>
        <Box sx={styles.headerWrapper}>
          <Box sx={styles.itemsWrapper}>
            <SearchField
              sx={styles.icons}
              placeholder="Search"
              debounceTimeout={500}
              onChange={(e) => handleSearchChange(e)}
            />
            <CSVLink
              data={csvData}
              headers={headers}
              filename={fileName}
              style={styles.exportButton}
              onClick={() => {
                window?.pendo?.track('Export Order History');
              }}
            >
              <img src={exportIcon} alt="export .csv" />
              <Typography variant="h6" sx={styles.exportButtonText}>Export CSV</Typography>
            </CSVLink>
          </Box>
          <Divider sx={styles.divider} />
        </Box>
        <Table sx={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCell}>
                <Typography variant="h4">Order Name</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Reference Id</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Team</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Date</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Order Acres</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Project Mileage</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Typography variant="h4">Charged Credits</Typography>
              </TableCell>
              <TableCell sx={styles.tableCell} align="right">
                <Box sx={styles.costHeader}>
                  <Typography variant="h4">Cost</Typography>
                  <WideLightTooltip
                    placement="bottom"
                    interactive
                    title={(
                      <Box>
                        Projects submitted prior to July 2022 will continue to display the prices charged at the time of submission.
                        Orders from July 2022 onwards have their add-ons charged in credits.
                      </Box>
                    )}
                  >
                    <Box sx={styles.infoIcon}>
                      <InfoIcon sx={styles.icon} />
                    </Box>
                  </WideLightTooltip>
                </Box>
              </TableCell>
              <TableCell sx={styles.iconTableCell} align="right" />
              {!_ADMIN_ && <TableCell sx={styles.tableCell} align="right" />}
            </TableRow>
          </TableHead>
          {orderList.length ? (
            <TableBody>
              {orderList.map((row: IOrder) => <OrderListItem order={row} key={row._id} />)}
            </TableBody>
          ) : null}
        </Table>
        {orderList.length === 0 ? (
          <Box sx={styles.noOrderContainer}>
            <img src={orderListIcon} alt="icon" />
            <Typography variant="h5">There are no orders to show.</Typography>
          </Box>
        ) : null}
      </Box>
    </LoadingSpinner>
  );
};

export default OrderHistory;
